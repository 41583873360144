import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

const firebaseApp = initializeApp(config)

const functions = getFunctions()
const auth = getAuth()

const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)

export { functions, auth, db, storage }
