/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Modal, Form, Input, Switch } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const validationSchema = () =>
  yup.object().shape({
    email: yup.string().email().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    phoneNumber: yup
      .string()
      .matches(/^\d+$/, 'The field should have digits only')
      .min(10)
      .max(10)
      .required(),
    password: yup.string().min(6).required(),
  })

const validationSchemaEdit = () =>
  yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    phoneNumber: yup
      .string()
      .matches(/^\d+$/, 'The field should have digits only')
      .min(10)
      .max(10)
      .required(),
  })

const AccountForm = ({ data, visible, onClose, onCreate, onEdit, loading }) => {
  const { setValue, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(
      data?.id ? validationSchemaEdit() : validationSchema()
    ),
  })

  const errors = formState.errors

  const onSubmit = async (values) => {
    if (data?.id) {
      onEdit(data?.id, {
        ...values,
        isAdmin: values.isAdmin ? values.isAdmin : false,
      })
    } else {
      onCreate({ ...values, isAdmin: values.isAdmin ? values.isAdmin : false })
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
    } else {
      reset()
    }
  }, [data])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={data?.id ? 'Edit Account' : 'Create Account'}
      okText={'Save'}
      cancelText={'Close'}
      confirmLoading={loading}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        {!data?.id && (
          <>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label="Email"
                  validateStatus={errors.email && 'error'}
                  help={errors.email && errors.email.message}
                >
                  <Input {...field} />
                </Form.Item>
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label="Password"
                  validateStatus={errors.password && 'error'}
                  help={errors.password && errors.password.message}
                >
                  <Input {...field} />
                </Form.Item>
              )}
            />
          </>
        )}
        <Controller
          name="firstname"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="First Name"
              validateStatus={errors.firstname && 'error'}
              help={errors.firstname && errors.firstname.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Last Name"
              validateStatus={errors.lastname && 'error'}
              help={errors.lastname && errors.lastname.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Telephone"
              validateStatus={errors.phoneNumber && 'error'}
              help={errors.phoneNumber && errors.phoneNumber.message}
            >
              <Input style={{ width: '100%' }} addonafter="฿" {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="isAdmin"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Admin"
              validateStatus={errors.phoneNumber && 'error'}
              help={errors.phoneNumber && errors.phoneNumber.message}
            >
              <Switch
                name="isAdmin"
                checked={field.value}
                onChange={(e) => setValue('isAdmin', e)}
              />
            </Form.Item>
          )}
        />
      </Form>
    </Modal>
  )
}

export default AccountForm
