/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
// import { useAppContext } from '../context'
import { collection, getDocs, doc, query } from 'firebase/firestore'
import { db } from '../config'
// import { SUCCESS } from '../config'

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const useDashBoard = () => {
  const [loading, setLoading] = useState(false)
  // const { user, setError, setMessage } = useAppContext()

  const getDashBoard = async () => {
    try {
      setLoading(true)

      let lessonsCount = 0

      const courseDocsSnapshot = await getDocs(query(collection(db, `course`)))
      const profileSnapshot = await getDocs(query(collection(db, `profile`)))

      const courseDocs = courseDocsSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      const courseDocsWithUsersSubscribe =
        (await Promise.all(
          courseDocs.map(async (course) => {
            const courseRef = doc(db, `course`, course.id)
            const profileRef = collection(courseRef, `profile`)
            const profileSnapshot = await getDocs(query(profileRef))
            const lessonRef = collection(courseRef, `lessons`)

            const lessonSnapshot = await getDocs(query(lessonRef))

            lessonsCount = lessonsCount + lessonSnapshot.size

            const subscribedUser = profileSnapshot.docs.map(
              (profile) => profile.id
            )

            return {
              name: truncateString(course?.header, 20),
              subscribers: subscribedUser.length,
            }
          })
        )) || []

      setLoading(false)
      //   setMessage({ type: SUCCESS, message: 'Get Dashboard successful' })

      return {
        lessonsCount,
        profileCount: profileSnapshot.size,
        courseCount: courseDocsSnapshot.size,
        courseDocsWithUsers: courseDocsWithUsersSubscribe,
      }
    } catch (error) {
      console.error(error)
      // setError(error)
    }
  }

  return {
    loading,
    getDashBoard,
  }
}
