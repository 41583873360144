/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'

import { useAppContext } from '../context'
import { db, SUCCESS } from '../config'
import {
  collection,
  getDocs,
  query,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  where,
} from 'firebase/firestore'

export const useContent = () => {
  const [loading, setLoading] = useState(false)

  const { setError, user, setMessage } = useAppContext()

  const getContents = async (id) => {
    try {
      setLoading(true)
      const contentRef = collection(db, `feeds`)

      const contentSnapshot = await getDocs(
        query(contentRef, where('type', '==', 'content'))
      )

      const contents = contentSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))
      setLoading(false)

      return contents
    } catch (error) {
      setError(error)
    }
  }

  const addContent = async (values) => {
    try {
      setLoading(true)

      let data = {
        title: values.title,
        detail: values.detail,
        type: 'content',
        createdAt: new Date(),
        userId: user?.uid,
        photoURL: values.photoURL,
        course: {
          id: '',
          title: '',
        },
        comments: [],
        likes: [],
      }

      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, 'feeds'), data)
      setLoading(false)

      setMessage({ type: SUCCESS, message: 'Add Content successful' })

      return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateContent = async (id, data) => {
    try {
      setLoading(true)

      await setDoc(doc(db, 'feeds', id), data, {
        merge: true,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Content successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteContent = async (contentId) => {
    try {
      setLoading(true)
      const contentRef = doc(db, `feeds`, contentId)

      await deleteDoc(contentRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Content successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  return {
    getContents,
    loading,
    deleteContent,
    addContent,
    updateContent,
  }
}
