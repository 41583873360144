/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useAppContext } from '../context'
import {
  collection,
  getDocs,
  getDoc,
  doc,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore'

import { db, functions } from '../config'
import { SUCCESS, ERROR } from '../config'
import { httpsCallable } from 'firebase/functions'

const createAccount = httpsCallable(functions, 'createAccount')

export const useAccount = () => {
  const [loading, setLoading] = useState(false)
  const { setError, setMessage } = useAppContext()

  const getAccounts = async (uid, search) => {
    try {
      setLoading(true)

      const profileDocsSnapshot = await getDocs(
        query(collection(db, `profile`), orderBy('createdAt', 'desc'))
      )

      const profileDocs = profileDocsSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      setLoading(false)

      const searchAccount = search ? search : profileDocs

      const profileDocsWithCourses =
        (await Promise.all(
          searchAccount.map(async (profile) => {
            const profileRef = doc(db, `profile`, profile.id)

            const courseRef = collection(profileRef, `course`)

            const courseSnapshot = await getDocs(query(courseRef))

            const courses =
              (await Promise.all(
                courseSnapshot.docs.map(async (course) => {
                  const courseDataSnapshot = await getDoc(
                    query(doc(db, `course`, course.id))
                  )
                  return {
                    id: course.id,
                    ...courseDataSnapshot.data(),
                  }
                })
              )) || []

            return { ...profile, courses }
          })
        )) || []

      return profileDocsWithCourses
    } catch (error) {
      setError(error)
    }
  }

  const getAccount = async (id) => {
    try {
      setLoading(true)
      const profileRef = doc(db, `profile`, id)
      const courseRef = collection(profileRef, `courses`)

      const profileSnapshot = await getDoc(profileRef)
      const profile = { id: profileSnapshot.id, ...profileSnapshot.data() }

      const courseSnapshot = await getDocs(
        query(courseRef, orderBy('order', 'asc'))
      )

      const courses = courseSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      setLoading(false)

      return { ...profile, courses }
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateAccount = async (id, data) => {
    try {
      setLoading(true)

      await setDoc(doc(db, 'profile', id), data, {
        merge: true,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Account successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteAccount = async (courseId) => {
    try {
      setLoading(true)
      //   const courseRef = doc(db, `course`, courseId)

      //   await deleteDoc(courseRef)
      //   setLoading(false)
      //   setMessage({ type: SUCCESS, message: 'Delete Account successful' })

      //   return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  // Sigup with Email and Password
  const addAccount = (values) => {
    console.log(values)
    setLoading(true)

    return createAccount(values)
      .then((res) => {
        return res
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })
        setLoading(false)
      })
  }

  async function searchAccount(searchKey) {
    searchKey.toString()
    try {
      const firstnameDocsSnapshot = await getDocs(
        query(
          collection(db, `profile`),
          where('firstname', '>=', searchKey),
          where('firstname', '<=', searchKey + '\uf8ff')
        )
      )

      const lastNameDocsSnapshot = await getDocs(
        query(
          collection(db, `profile`),
          where('lastname', '>=', searchKey),
          where('lastname', '<=', searchKey + '\uf8ff')
        )
      )

      const emailDocsSnapshot = await getDocs(
        query(
          collection(db, `profile`),
          where('email', '>=', searchKey),
          where('email', '<=', searchKey + '\uf8ff')
        )
      )

      const phoneDocsSnapshot = await getDocs(
        query(
          collection(db, `profile`),
          where('phoneNumber', '>=', searchKey),
          where('phoneNumber', '<=', searchKey + '\uf8ff')
        )
      )

      const firstnameDocs = firstnameDocsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      const lastNameDocs = lastNameDocsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      const emailDocs = emailDocsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      const phoneDocs = phoneDocsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      const search = [
        ...firstnameDocs.flat(),
        ...lastNameDocs.flat(),
        ...emailDocs.flat(),
        ...phoneDocs.flat(),
      ].filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.place === value.place && t.id === value.id)
      )
      const account = await getAccounts(null, search)
      return account
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getAccounts,
    getAccount,
    loading,
    deleteAccount,
    addAccount,
    updateAccount,
    searchAccount,
  }
}
