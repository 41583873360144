import React, { useState, useEffect } from 'react'

import { Layout } from 'antd'
import Navbar from '../Navbar'
import SideMenu from '../SideMenu'
import { enquireScreen, unenquireScreen } from 'enquire-js'

const { Content } = Layout

function MainLayout({ user, children }) {
  const [collapsed, setCollapsed] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    enquireHandler()

    return () => {
      unenquireScreen(enquireHandler)
    }
    // eslint-disable-next-line
  }, [])

  const enquireHandler = () => {
    enquireScreen((mobile) => {
      mobile ? setCollapsed(true) : setCollapsed(false)
      if (isMobile !== mobile) {
        setIsMobile(mobile)
      }
    })
  }
  return (
    <Layout>
      <Navbar email={user?.email} />
      <Layout>
        <SideMenu collapsed={collapsed} />
        <Layout
          style={{
            padding: '0 25px 25px',
            backgroundColor: '#f6f6f6',
            width: '100%',
          }}
        >
          <Content
            style={{
              marginTop: 100,
              minHeight: '100vh',
              padding: 10,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default MainLayout
