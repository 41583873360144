/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import MainLayout from '../../Components/MainLayout'
import { Table, Space, Breadcrumb, Card, Button, Popconfirm, Image } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { useBanner } from '../../hooks'
import BannerForm from './BannerForm'

function Banner({ user }) {
  const { getBanners, loading, deleteBanner, addBanner, updateBanner } =
    useBanner()
  const [banners, setBanners] = useState([])

  const [bannerDialog, setBannerDialog] = useState({
    open: false,
    data: null,
  })

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getBanners(uid)

    setBanners(result)
  }

  const onCreate = async (data) => {
    const result = await addBanner(data)
    if (result) {
      fetchData(user.uid)
      setBannerDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEdit = async (id, data) => {
    const result = await updateBanner(id, data)
    if (result === 'success') {
      fetchData(user.uid)
      setBannerDialog({
        open: false,
        data: null,
      })
    }
  }

  const onDelete = async (id) => {
    const result = await deleteBanner(id)
    if (result === 'success') {
      fetchData(user.uid)
    }
  }

  const columns = [
    {
      title: 'Order',
      dataIndex: 'order',
      width: 150,
      key: 'order',
      align: 'center',
    },
    {
      title: 'Image',
      dataIndex: 'photoURL',
      key: 'photoURL',
      render: (url) => (
        <Image
          src={url}
          alt={'banner'}
          width={200}
          style={{ maxHeight: 200 }}
        />
      ),
    },

    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setBannerDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this Banner?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" href="#">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <MainLayout user={user}>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Banner</Breadcrumb.Item>
      </Breadcrumb>

      <Card
        title="Banner List"
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setBannerDialog({
                open: true,
                data: null,
              })
            }}
          >
            New Banner
          </Button>
        }
      >
        <Table
          scroll={{ x: 'max-content' }}
          rowKey={(record) => `${record.id}`}
          columns={columns}
          dataSource={banners}
          loading={loading}
        />
      </Card>
      {bannerDialog.open && (
        <BannerForm
          visible={bannerDialog.open}
          onClose={() =>
            setBannerDialog({
              open: false,
              data: null,
            })
          }
          onCreate={onCreate}
          data={bannerDialog.data}
          onEdit={onEdit}
        />
      )}
    </MainLayout>
  )
}

export default Banner
