import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import './index.less'
import App from './App'
import reportWebVitals from './reportWebVitals'
import enUS from 'antd/lib/locale/en_US'
import { ContextProvider } from './context'

ReactDOM.render(
  <ConfigProvider locale={enUS}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
