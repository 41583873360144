import { useState } from 'react'
import {
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { storage } from '../config'
import { useAppContext } from '../context'

function useImage() {
  const [uploading, setUploading] = useState(false)
  const { setError } = useAppContext()
  const [progress, setProgress] = useState(0)

  function upload(file, filename, folder, callback) {
    setUploading(true)
    const storageRef = ref(
      storage,
      `${folder}/${new Date().getTime()}-${filename}`
    )
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
      },
      (error) => {
        setError(error)
        setUploading(false)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
          callback(downloadURL)
        )
        setUploading(false)
      }
    )
  }

  const multipleUpload = async (images, folder, results) => {
    setUploading(true)
    const promises = []

    images.forEach((image) => {
      const storageRef = ref(
        storage,
        `${folder}/${new Date().getTime()}-${image?.file.name}`
      )
      const uploadTask = uploadBytesResumable(storageRef, image?.file)

      promises.push(
        uploadTask
          .then((uploadResult) => {
            return getDownloadURL(uploadResult.ref)
          })
          .then((url) => ({
            url,
            description: image?.description || '',
          }))
      )
    })

    return await Promise.all(promises)
  }

  const deleteFile = async (url) => {
    const desertRef = ref(storage, url)

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        console.log(' File deleted successfully')
        // File deleted successfully
      })
      .catch((error) => {
        console.error(error)
        // Uh-oh, an error occurred!
      })
  }

  return { upload, multipleUpload, uploading, progress, deleteFile }
}

export { useImage }
