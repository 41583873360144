import { Route } from 'react-router-dom'

import Login from '../Pages/Login'
import Dashboard from '../Pages/Dashboard'
import OnlineClass from '../Pages/OnlineClass'
import Lesson from '../Pages/OnlineClass/Lesson'
import Account from '../Pages/Account'
import Content from '../Pages/Content'
import Banner from '../Pages/Banner'
import Testimonial from '../Pages/Testimonial'
import Profile from '../Pages/Profile'

const privatePaths = [
  { path: '/onlineclass/:id', component: Lesson },
  { path: '/onlineclass', component: OnlineClass },
  { path: '/account', component: Account },
  { path: '/content', component: Content },
  { path: '/banner', component: Banner },
  { path: '/testimonial', component: Testimonial },
  { path: '/profile', component: Profile },

  { path: '/', component: Dashboard },
]

const publicPaths = [{ path: '/', component: Login }]

export const PublicRoute = ({ login, loading }) => (
  <>
    {publicPaths.map(({ path, component: Component }, key) => (
      <Route
        key={key}
        path={path}
        render={(props) => <Component login={login} loading={loading} />}
      />
    ))}
  </>
)

export const PrivateRoute = ({ user }) => (
  <>
    {privatePaths.map(({ path, component: Component }, key) => (
      <Route
        exact
        key={key}
        path={path}
        render={(props) => <Component user={user} {...props} />}
      />
    ))}
  </>
)
