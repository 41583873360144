/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UploadImage from '../../../Components/Upload/image'
import UploadVideo from '../../../Components/Upload/video'

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required('Title is Required'),
    description: yup.string().required(),
    video: yup.string().required(),
    thumbnail: yup.string(),
    minutes: yup.number().required(),
    order: yup.number().required(),
  })

const LessonForm = ({
  courseName,
  data,
  visible,
  onClose,
  onCreate,
  onEdit,
}) => {
  const { setValue, handleSubmit, formState, control, reset, setError } =
    useForm({
      resolver: yupResolver(validationSchema()),
    })

  const errors = formState.errors

  const onSubmit = async (values) => {
    if (data?.id) {
      onEdit(data?.id, values)
    } else {
      onCreate(values)
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
    } else {
      reset()
    }
  }, [data])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={data?.id ? 'Edit Lesson' : 'Create Lesson'}
      okText={'Save'}
      cancelText={'Close'}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Title"
              validateStatus={errors.title && 'error'}
              help={errors.title && errors.title.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Descriptions"
              validateStatus={errors.description && 'error'}
              help={errors.description && errors.description.message}
            >
              <Input.TextArea rows={8} {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="thumbnail"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Thumbnail"
              validateStatus={errors.thumbnail && 'error'}
              help={errors.thumbnail && errors.thumbnail.message}
            >
              <UploadImage
                {...field}
                noSquare
                onImageChange={setValue}
                folder={`lesson/thumbnail/${courseName}`}
                setError={setError}
              />
            </Form.Item>
          )}
        />
        <Controller
          name="video"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Video"
              validateStatus={errors.video && 'error'}
              help={errors.video && errors.video.message}
            >
              <UploadVideo
                {...field}
                onImageChange={setValue}
                folder={`lesson/video/${courseName}`}
                setError={setError}
              />
            </Form.Item>
          )}
        />
        <Controller
          name="minutes"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Minutes"
              validateStatus={errors.minutes && 'error'}
              help={errors.minutes && errors.minutes.message}
            >
              <InputNumber style={{ width: '100%' }} {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Order"
              validateStatus={errors.order && 'error'}
              help={errors.order && errors.order.message}
            >
              <InputNumber style={{ width: '100%' }} {...field} />
            </Form.Item>
          )}
        />
      </Form>
    </Modal>
  )
}

export default LessonForm
