/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import MainLayout from '../../Components/MainLayout'
import {
  Table,
  Space,
  Breadcrumb,
  Card,
  Button,
  Popconfirm,
  Tooltip,
} from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { useContent } from '../../hooks'
import ContentForm from './ContentForm'

function OnlineContent({ user }) {
  const { getContents, loading, deleteContent, addContent, updateContent } =
    useContent()
  const [contents, setContents] = useState([])
  const [contentDialog, setContentDialog] = useState({
    open: false,
    data: null,
  })

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getContents(uid)

    setContents(result)
  }

  const onCreate = async (data) => {
    const result = await addContent(data)
    if (result) {
      fetchData(user.uid)
      setContentDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEdit = async (id, data) => {
    const result = await updateContent(id, data)
    if (result === 'success') {
      fetchData(user.uid)
      setContentDialog({
        open: false,
        data: null,
      })
    }
  }

  const onDelete = async (id) => {
    const result = await deleteContent(id)
    if (result === 'success') {
      fetchData(user.uid)
    }
  }

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no.',
      width: 50,
      key: 'no.',
      align: 'center',
      render: (data, row, index) => {
        return index + 1
      },
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'detail',
      key: 'detail',
      width: '50%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        }
      },
      render: (text) => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setContentDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this Content?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" href="#">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <MainLayout user={user}>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Content</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title="Content List"
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setContentDialog({
                open: true,
                data: null,
              })
            }}
          >
            New Content
          </Button>
        }
      >
        <Table
          scroll={{ x: 'max-content' }}
          rowKey={(record) => `${record.id}`}
          columns={columns}
          dataSource={contents?.sort((a, b) => b.createdAt - a.createdAt)}
          loading={loading}
        />
      </Card>
      {contentDialog.open && (
        <ContentForm
          visible={contentDialog.open}
          onClose={() =>
            setContentDialog({
              open: false,
              data: null,
            })
          }
          onCreate={onCreate}
          data={contentDialog.data}
          onEdit={onEdit}
        />
      )}
    </MainLayout>
  )
}

export default OnlineContent
