/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Modal, Form, Input } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UploadImage from '../../../Components/Upload/image'

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required('Title is Required'),
    detail: yup.string().required(),
    photoURL: yup.string().required(),
  })

const ClassForm = ({ data, visible, onClose, onCreate, onEdit }) => {
  const { setValue, handleSubmit, formState, control, reset, setError } =
    useForm({
      resolver: yupResolver(validationSchema()),
    })

  const errors = formState.errors

  const onSubmit = async (values) => {
    if (data?.id) {
      onEdit(data?.id, values)
    } else {
      onCreate(values)
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
    } else {
      reset()
    }
  }, [data])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={data?.id ? 'Edit Content' : 'Create Content'}
      okText={'Save'}
      cancelText={'Close'}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Title"
              validateStatus={errors.title && 'error'}
              help={errors.title && errors.title.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />

        <Controller
          name="detail"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Descriptions"
              validateStatus={errors.detail && 'error'}
              help={errors.detail && errors.detail.message}
            >
              <Input.TextArea rows={8} {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="photoURL"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Image"
              validateStatus={errors.photoURL && 'error'}
              help={errors.photoURL && errors.photoURL.message}
            >
              <UploadImage
                {...field}
                onImageChange={setValue}
                folder="content"
                setError={setError}
                noCrop
              />
            </Form.Item>
          )}
        />
      </Form>
    </Modal>
  )
}

export default ClassForm
