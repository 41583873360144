/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import './App.less'
import { PrivateRoute, PublicRoute } from './routes'
import { useAppContext } from './context'
import { message as Message, Spin } from 'antd'
import { useAuth } from './hooks'

function App() {
  const { message, setMessage } = useAppContext()
  const { loading, isLogin, user, login } = useAuth()
  const [isMounted, setMount] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage(null)
    }, 4000)
    if (message) {
      Message[message.type]({
        content: message.message,
        duration: 2,
        onClose: () => setMessage(null),
      })
    }
    return () => clearTimeout(timer)
  }, [message])

  useEffect(() => {
    if (user !== undefined) {
      setMount(true)
    }
    return () => setMount(false)
  }, [user])

  if (!isMounted)
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" tip="Loading..." />
      </div>
    )

  return (
    isMounted && (
      <Router>
        <Switch>
          {isLogin ? (
            <PrivateRoute user={user} />
          ) : (
            <PublicRoute login={login} loading={loading} />
          )}
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    )
  )
}

export default App
