/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UploadFile from '../../../Components/Upload/file'

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required('Title is Required'),
    file: yup.string().required(),
    order: yup.number().required(),
  })

const DocumentForm = ({
  data,
  visible,
  onClose,
  onCreate,
  onEdit,
  courseName,
}) => {
  const { setValue, handleSubmit, formState, control, reset, setError } =
    useForm({
      resolver: yupResolver(validationSchema()),
    })

  const errors = formState.errors

  const onSubmit = async (values) => {
    if (data?.id) {
      onEdit(data?.id, values)
    } else {
      onCreate(values)
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
    } else {
      reset()
    }
  }, [data])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={data?.id ? 'Edit Document' : 'Add Document'}
      okText={'Save'}
      cancelText={'Close'}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Title"
              validateStatus={errors.header && 'error'}
              help={errors.header && errors.header.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="file"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="File"
              validateStatus={errors.file && 'error'}
              help={errors.file && errors.file.message}
            >
              <UploadFile
                {...field}
                onImageChange={setValue}
                folder={`class/documents/${courseName}`}
                setError={setError}
              />
            </Form.Item>
          )}
        />
        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Order"
              validateStatus={errors.order && 'error'}
              help={errors.order && errors.order.message}
            >
              <InputNumber style={{ width: '100%' }} {...field} />
            </Form.Item>
          )}
        />
      </Form>
    </Modal>
  )
}

export default DocumentForm
