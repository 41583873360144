/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Modal, Form, Input } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const validationSchema = () =>
  yup.object().shape({
    faq: yup.string().required(),
  })

const FAQForm = ({ data, visible, onClose, onEdit }) => {
  const { setValue, handleSubmit, formState, control, reset } =
    useForm({
      resolver: yupResolver(validationSchema()),
    })

  const errors = formState.errors

  const onSubmit = async (values) => {
    if (data?.id) {
      onEdit(data?.id, values)
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
    } else {
      reset()
    }
  }, [data])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={'Edit FAQ'}
      okText={'Save'}
      cancelText={'Close'}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        <Controller
          name="faq"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="FAQ"
              validateStatus={errors.faq && 'error'}
              help={errors.faq && errors.faq.message}
            >
              <Input.TextArea rows={8} {...field} />
            </Form.Item>
          )}
        />
      </Form>
    </Modal>
  )
}

export default FAQForm
