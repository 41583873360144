import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import CountUp from 'react-countup'
import iconMap from '../../../utils/iconMap'
import styles from './styles'

function NumberCard({ icon, color, title, number, countUp, currency }) {
  return (
    <Card
      style={styles.numberCard}
      bordered={false}
      bodyStyle={{ padding: 10 }}
    >
      <span style={{ ...styles.iconWarp, color: color }}>{iconMap[icon]}</span>
      <div style={styles.content}>
        <p style={styles.title}>{title || 'No Title'}</p>
        <p style={styles.number}>
          <CountUp
            start={0}
            end={number}
            duration={2.75}
            useEasing
            useGrouping
            separator=","
            {...(countUp || {})}
          />
          {currency && ` ${currency}`}
        </p>
      </div>
    </Card>
  )
}

NumberCard.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  number: PropTypes.number,
  countUp: PropTypes.object,
}

export default NumberCard
