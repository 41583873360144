/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useCallback } from 'react'
import MainLayout from '../../Components/MainLayout'
import { Breadcrumb, Card, Button, Select } from 'antd'
import { Link } from 'react-router-dom'
import { ReactPictureGrid } from 'react-picture-grid'

import { useTestimonial } from '../../hooks'
import TestimonialForm from './TestimonialForm'

const { Option } = Select

const classList = [
  'All',
  'Scone',
  'Fresh Cream Cake',
  'Premium Drink',
  'Say Cheese',
  'Say Choc',
  'Say Choux',
  'Donut Party',
  'Coconut Cloud Cake',
  'Soft Cookies',
]

function Testimonial({ user }) {
  const { getTestimonials, loading, addTestimonial, updateTestimonial } =
    useTestimonial()
  const [testimonials, setTestimonials] = useState()
  const [onlineClass, setOnlineClass] = useState('All')
  const [testimonialDialog, setTestimonialDialog] = useState({
    open: false,
    data: null,
  })

  useEffect(() => {
    if (user?.uid) {
      fetchImages()
    }
  }, [user, onlineClass])

  const fetchImages = useCallback(async () => {
    console.log('fetch...')
    const result = await getTestimonials(onlineClass)
    const images = result.map((image) => ({ ...image, image: image.url }))
    setTestimonials(images)
  })

  const onCreate = async (data, images, folder) => {
    const result = await addTestimonial(data, images, folder)
    if (result) {
      fetchImages()
      setTestimonialDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEdit = async (data, images, folder, removeImages) => {
    const result = await updateTestimonial(data, images, folder, removeImages)
    if (result === 'success') {
      fetchImages()
      setTestimonialDialog({
        open: false,
        data: null,
      })
    }
  }

  return (
    <MainLayout user={user}>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Testimonial</Breadcrumb.Item>
      </Breadcrumb>

      <Card
        title={
          <Select
            style={{ width: '200px' }}
            placeholder="Select Online Class"
            defaultValue="All"
            onChange={(value) => {
              console.log(value)
              setOnlineClass(value)
            }}
          >
            {classList.map((value, key) => (
              <Option value={value} key={key}>
                {value}
              </Option>
            ))}
          </Select>
        }
        extra={
          <Button
            onClick={() => {
              setTestimonialDialog({
                open: true,
                data: testimonials,
              })
            }}
          >
            Manage Images
          </Button>
        }
      >
        <ReactPictureGrid data={testimonials} showPreview gap={10} />
      </Card>
      {testimonialDialog.open && (
        <TestimonialForm
          visible={testimonialDialog.open}
          onClose={() =>
            setTestimonialDialog({
              open: false,
              data: null,
            })
          }
          onlineClass={onlineClass}
          onCreate={onCreate}
          data={testimonialDialog.data}
          onEdit={onEdit}
          loading={loading}
          fetchImages={fetchImages}
          classList={classList}
        />
      )}
    </MainLayout>
  )
}

export default Testimonial
