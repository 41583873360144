/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'

import { useAppContext } from '../context'
import { db, SUCCESS } from '../config'
import {
  collection,
  getDocs,
  query,
  doc,
  deleteDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { useImage } from '../hooks'

export const useTestimonial = () => {
  const [loading, setLoading] = useState(false)
  const { multipleUpload, deleteFile } = useImage()
  const { setError, setMessage } = useAppContext()

  const getTestimonials = async (onlineClass) => {
    try {
      setLoading(true)
      const testimonialRef = collection(db, `testimonials`)
      const testimonialSnapshot = await getDocs(
        // query(testimonialRef, orderBy('order', 'asc'))
        onlineClass === 'All'
          ? query(testimonialRef)
          : query(testimonialRef, where('onlineClass', '==', onlineClass))
      )

      const testimonials = testimonialSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))
      setLoading(false)

      return testimonials
    } catch (error) {
      setError(error)
    }
  }

  const addTestimonial = async (data, images, folder) => {
    try {
      setLoading(true)

      const results = await multipleUpload(images, folder)

      const batch = writeBatch(db)
      const ImagesBatch = []

      for (let i = 0; i < results.length; i++) {
        const transaction = {
          url: results[i].url,
          onlineClass: data.onlineClass,
        }
        ImagesBatch.push(transaction)
      }

      await ImagesBatch.map(async (item) => {
        const docRef = doc(collection(db, 'testimonials'))

        batch.set(docRef, item)
      })
      await batch.commit()

      setLoading(false)
      setMessage({
        type: SUCCESS,
        message: 'Add Testimonial Images successful',
      })
      return 'success'
      // return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateTestimonial = async (data, images, folder, removeImages) => {
    try {
      setLoading(true)
      const newImages = images.filter((image) => image?.file)

      const batch = writeBatch(db)
      const ImagesBatch = []
      if (newImages.length > 0) {
        const results = await multipleUpload(newImages, folder)

        for (let i = 0; i < results.length; i++) {
          const transaction = {
            url: results[i].url,
            onlineClass: data.onlineClass,
          }
          ImagesBatch.push(transaction)
        }

        await ImagesBatch.forEach((item) => {
          const docRef = doc(collection(db, 'testimonials'))

          batch.set(docRef, item)
        })
      } else if (removeImages.length > 0) {
        await removeImages.forEach(async (image) => {
          const docRef = doc(db, `testimonials`, `${image.id}`)
          batch.delete(docRef)
          deleteFile(image.url)
        })
      }
      await batch.commit()

      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Testimonial successful' })
      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteTestimonial = async (courseId, id) => {
    try {
      setLoading(true)
      const courseRef = doc(db, `course/${courseId}/testimonial`, id)
      await deleteDoc(courseRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Testimonial successful' })
      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  return {
    getTestimonials,
    loading,
    deleteTestimonial,
    addTestimonial,
    updateTestimonial,
  }
}
