import { useState, useEffect } from 'react'
import { Modal, Table, Switch, Button } from 'antd'

const CourseList = ({ data, visible, onClose, onEdit, courses, loading }) => {
  const [profile, setProfile] = useState()
  const [courseList, setCourses] = useState([])

  const onChange = async (courseId, subscribed) => {
    onEdit(data?.id, courseId, subscribed)
  }

  useEffect(() => {
    setProfile(data)
    setCourses(courses)
  }, [data, courses])

  const columns = [
    {
      title: 'No.',
      key: 'no',
      dataIndex: 'no',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Title',
      dataIndex: 'header',
      key: 'header',
    },

    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      align: 'right',
      render: (text) => (
        <Button type="link">
          {text?.toLocaleString('th-TH', {
            style: 'currency',
            currency: 'THB',
            minimumFractionDigits: 0,
          })}
        </Button>
      ),
    },

    {
      title: 'Subscribed',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        const subscribed = record.users.some((id) => {
          return id === profile.id
        })

        return (
          <Switch
            // loading={loading}
            defaultChecked={subscribed}
            onChange={() => onChange(record.id, subscribed)}
          />
        )
      },
    },
  ]

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={'Course List'}
      onCancel={() => {
        onClose()
      }}
      onOk={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Table
        scroll={{ x: 'max-content' }}
        // size='small'
        rowKey={(record) => `${record.id}`}
        columns={columns}
        dataSource={courseList}
        // loading={loading}
        // onChange={handleTableChange}
        // pagination={{
        //   position: 'bottom',
        //   defaultPageSize: 10,
        //   showSizeChanger: true,
        //   pageSizeOptions: ['10', '25', '50', '100'],
        //   total: pagination.total,
        //   current: pagination.currentPage,
        //   showTotal: (total, range) =>
        //     `${range[0]}-${range[1]} of ${total} items`,
        // }}
      />
    </Modal>
  )
}

export default CourseList
