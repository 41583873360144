/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'

import { useAppContext } from '../context'
import { db, SUCCESS } from '../config'
import {
  collection,
  getDocs,
  query,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  orderBy,
} from 'firebase/firestore'

export const useBanner = () => {
  const [loading, setLoading] = useState(false)

  const { setError, user, setMessage } = useAppContext()

  const getBanners = async (id) => {
    try {
      setLoading(true)
      const bannerRef = collection(db, `banner`)

      const bannerSnapshot = await getDocs(
        query(bannerRef, orderBy('order', 'asc'))
      )

      const banners = bannerSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))
      setLoading(false)

      return banners
    } catch (error) {
      setError(error)
    }
  }

  const addBanner = async (values) => {
    try {
      setLoading(true)

      let data = {
        ...values,
        createdAt: new Date(),
        createdBy: user?.uid,
      }

      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, 'banner'), data)
      setLoading(false)

      setMessage({ type: SUCCESS, message: 'Add Banner successful' })

      return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateBanner = async (id, data) => {
    try {
      setLoading(true)

      await setDoc(doc(db, 'banner', id), data, {
        merge: true,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Banner successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteBanner = async (bannerId) => {
    try {
      setLoading(true)
      const bannerRef = doc(db, `banner`, bannerId)

      await deleteDoc(bannerRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Banner successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  return {
    getBanners,
    loading,
    deleteBanner,
    addBanner,
    updateBanner,
  }
}
