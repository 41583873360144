import React, { useState, useEffect } from 'react'
import { Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useImage } from '../../hooks'

const UploadFile = ({
  name,
  value,
  onImageChange,
  folder,
  setError
}) => {
  const [fileList, setFileList] = useState([])
  const { upload } = useImage()

  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: '-1',
          name: value,
          status: 'done',
          url: value,
        },
      ])
    }
  }, [value])

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    if (newFileList.length <= 0) {
      onImageChange(name, '')
    } else {
      setError(name, undefined)
    }
  }


  const props = {
    customRequest({ file, onSuccess }) {
      upload(file, file.name, folder, (fileURL) => {
        setFileList([
          {
            uid: '-1',
            name: file.name,
            status: 'done',
            url: fileURL,
          },
        ])
        onImageChange(name, fileURL)
        onSuccess('ok', file)
      })

      return {
        abort() {
          console.log('upload progress is aborted.')
        },
      }
    },
  }

  return (<Upload
    {...props}
    fileList={fileList}
    onChange={onChange}
  >
    {
      fileList.length < 1 &&  <Button icon={<UploadOutlined />}>Click to Upload</Button>
    }
   
  </Upload>)
}

export default UploadFile
