/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import MainLayout from '../../../Components/MainLayout'
import {
  Table,
  Tooltip,
  Space,
  Breadcrumb,
  Card,
  Button,
  Popconfirm,
  Tabs,
} from 'antd'
import { Link, useParams } from 'react-router-dom'
import {
  PlusOutlined,
  FileAddOutlined,
  FileOutlined,
  QuestionCircleOutlined,
  PictureOutlined,
  FileImageOutlined,
} from '@ant-design/icons'
import { useLesson } from '../../../hooks'
import LessonForm from '../LessonForm'
import DocumentForm from '../DocumentForm'
import FAQForm from '../FAQForm'
import AlbumsForm from '../AlbumsForm'

const { TabPane } = Tabs

function Lesson({ user }) {
  const { id } = useParams()
  const {
    getCourse,
    updateCourse,
    loading,
    addLesson,
    updateLesson,
    deleteLesson,
    addDocument,
    updateDocument,
    deleteDocument,
    addAlbum,
    updateAlbum,
    deleteAlbum,
  } = useLesson()
  const [course, setCourse] = useState()
  const [lessonDialog, setLessonDialog] = useState({
    open: false,
    data: null,
  })
  const [docDialog, setDocDialog] = useState({
    open: false,
    data: null,
  })
  const [faqDialog, setFaqDialog] = useState({
    open: false,
    data: null,
  })
  const [albumDialog, setAlbumDialog] = useState({
    open: false,
    data: null,
  })

  useEffect(() => {
    if (user?.uid && id) {
      fetchData(id)
    }
  }, [user, id])

  const fetchData = async (id) => {
    const result = await getCourse(id)
    setCourse(result)
  }

  const onCreate = async (data) => {
    const result = await addLesson(id, data)
    if (result) {
      fetchData(id)
      setLessonDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEdit = async (lessonId, data) => {
    const result = await updateLesson(id, lessonId, data)
    if (result === 'success') {
      fetchData(id)
      setLessonDialog({
        open: false,
        data: null,
      })
    }
  }

  const onDelete = async (lessonId) => {
    const result = await deleteLesson(id, lessonId)
    if (result === 'success') {
      fetchData(id)
    }
  }

  const onCreateDoc = async (data) => {
    const result = await addDocument(id, data)
    if (result) {
      fetchData(id)
      setDocDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEditDoc = async (docId, data) => {
    const result = await updateDocument(id, docId, data)
    if (result === 'success') {
      fetchData(id)
      setDocDialog({
        open: false,
        data: null,
      })
    }
  }

  const onDeleteDoc = async (docId) => {
    const result = await deleteDocument(id, docId)
    if (result === 'success') {
      fetchData(id)
    }
  }

  const onEditFAQ = async (id, data) => {
    const result = await updateCourse(id, data)
    if (result === 'success') {
      fetchData(id)
      setFaqDialog({
        open: false,
        data: null,
      })
    }
  }

  const onCreateAlbum = async (data, images, folder) => {
    const result = await addAlbum(id, data, images, folder)
    if (result) {
      fetchData(id)
      setAlbumDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEditAlbum = async (docId, data, images, folder, removeImages) => {
    const result = await updateAlbum(
      id,
      docId,
      data,
      images,
      folder,
      removeImages
    )
    if (result === 'success') {
      fetchData(id)
      setAlbumDialog({
        open: false,
        data: null,
      })
    }
  }

  const onDeleteAlbum = async (docId) => {
    const result = await deleteAlbum(id, docId)
    if (result === 'success') {
      fetchData(id)
    }
  }

  const columns = [
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <Button type="link">{text}</Button>,
    },
    {
      title: 'Minutes',
      dataIndex: 'minutes',
      key: 'minutes',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        }
      },
      render: (text) => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setLessonDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this Lesson?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const docColumns = [
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'File',
      key: 'file',
      dataIndex: 'file',
      render: (text, record) => (
        <a href={text} target="_blank" rel="noreferrer">
          <FileOutlined /> View
        </a>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setDocDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this document?"
            onConfirm={() => onDeleteDoc(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const albumColumns = [
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Images',
      key: 'images',
      dataIndex: 'images',
      render: (images, record) => (
        <div>
          <FileImageOutlined /> {images.length} Images
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setAlbumDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this album?"
            onConfirm={() => onDeleteAlbum(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <MainLayout user={user}>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/onlineclass">Online Class</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{course?.header}</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={course?.header}
        extra={
          <>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setLessonDialog({
                  open: true,
                  data: null,
                })
              }}
            >
              New Lesson
            </Button>
            &nbsp;
            <Button
              icon={<FileAddOutlined />}
              onClick={() => {
                setDocDialog({
                  open: true,
                  data: null,
                })
              }}
            >
              New Document
            </Button>
            &nbsp;
            <Button
              icon={<PictureOutlined />}
              onClick={() => {
                setAlbumDialog({
                  open: true,
                  data: null,
                })
              }}
            >
              New Albums
            </Button>
            &nbsp;
            <Button
              icon={<QuestionCircleOutlined />}
              onClick={() => {
                setFaqDialog({
                  open: true,
                  data: { faq: course.faq, id: course.id },
                })
              }}
            >
              FAQ
            </Button>
          </>
        }
      >
        <Tabs type="card">
          <TabPane tab="Lesson" key="1">
            <Table
              scroll={{ x: 'max-content' }}
              rowKey={(record) => `${record.id}`}
              columns={columns}
              dataSource={course?.lessons}
              loading={loading}
            />
          </TabPane>
          <TabPane tab="Documents" key="2">
            <Table
              scroll={{ x: 'max-content' }}
              rowKey={(record) => `${record.id}`}
              columns={docColumns}
              dataSource={course?.documents}
              loading={loading}
            />
          </TabPane>
          <TabPane tab="Albums" key="3">
            <Table
              scroll={{ x: 'max-content' }}
              rowKey={(record) => `${record.id}`}
              columns={albumColumns}
              dataSource={course?.albums}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </Card>
      {lessonDialog.open && (
        <LessonForm
          visible={lessonDialog.open}
          onClose={() =>
            setLessonDialog({
              open: false,
              data: null,
            })
          }
          data={lessonDialog.data}
          onEdit={onEdit}
          onCreate={onCreate}
          courseName={course?.header}
        />
      )}
      {docDialog.open && (
        <DocumentForm
          visible={docDialog.open}
          onClose={() =>
            setDocDialog({
              open: false,
              data: null,
            })
          }
          data={docDialog.data}
          onEdit={onEditDoc}
          onCreate={onCreateDoc}
          courseName={course?.header}
        />
      )}
      {faqDialog.open && (
        <FAQForm
          visible={faqDialog.open}
          onClose={() =>
            setFaqDialog({
              open: false,
              data: null,
            })
          }
          data={faqDialog.data}
          onEdit={onEditFAQ}
          courseName={course?.header}
        />
      )}
      {albumDialog.open && (
        <AlbumsForm
          visible={albumDialog.open}
          onClose={() =>
            setAlbumDialog({
              open: false,
              data: null,
            })
          }
          data={albumDialog.data}
          onCreate={onCreateAlbum}
          onEdit={onEditAlbum}
          courseName={course?.header}
        />
      )}
    </MainLayout>
  )
}

export default Lesson
