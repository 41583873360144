import React, { useState, useEffect, forwardRef } from 'react'

import { Upload, message, Button, Spin } from 'antd'
import { useImage } from '../../hooks'
import { UploadOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player/lazy'

const UploadVideo = forwardRef(
  ({ name, value, onImageChange, folder, setError }, ref) => {
    const { upload } = useImage()
    const [fileList, setFileList] = useState([])
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
      if (value) {
        setFileList([
          {
            uid: '-1',
            name: value,
            status: 'done',
            url: value,
          },
        ])
      }
    }, [value])

    const props = {
      customRequest({ file, onSuccess }) {
        setUploading(true)

        upload(file, file.name, folder, (url) => {
          onImageChange(name, url)
          onSuccess(setUploading(false), file)
          setFileList([
            {
              uid: '-1',
              name: file.name,
              status: 'done',
              url: url,
            },
          ])
        })

        return {
          abort() {
            console.log('upload progress is aborted.')
          },
        }
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList)
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`)
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`)
        }

        if (info.fileList.length <= 0) {
          onImageChange(name, '')
        } else {
          setError(name, undefined)
        }
      },
    }

    return (
      <>
        {value && <ReactPlayer url={value} controls width="100%" />}
        <Upload
          {...props}
          icon={<UploadOutlined />}
          fileList={fileList}
          ref={ref}
        >
          {uploading ? (
            <Spin style={{ marginTop: 15 }} tip="Uploading..." />
          ) : (
            <Button style={{ marginTop: 15 }} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          )}
        </Upload>
      </>
    )
  }
)

export default UploadVideo
