/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Image, Row, Col, Space, Typography } from 'antd'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import ImageUploading from 'react-images-uploading'
import { yupResolver } from '@hookform/resolvers/yup'

const validationSchema = () => yup.object().shape({})

const TestimonialForm = ({
  data,
  visible,
  onClose,
  onCreate,
  onEdit,
  onlineClass,
  classList,
}) => {
  const { setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema()),
  })

  const [images, setImages] = useState([])
  const [removeImages, setRemove] = useState([])

  const maxNumber = 999
  const onChange = (imageList) => {
    setImages(imageList)
    setValue('images', imageList)
  }

  const onSubmit = async (values) => {
    if (data) {
      onEdit(values, images, `testimonial`, removeImages)
    } else {
      onCreate(values, images, `testimonial`)
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
      setImages(data)
    } else {
      reset()
    }
  }, [data])

  useEffect(() => {
    setValue('onlineClass', onlineClass)
  }, [onlineClass])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="90vw"
      visible={visible}
      title="Manange Images"
      okText={'Save'}
      cancelText={'Close'}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        <Form.Item>
          <Typography.Title level={3}>{onlineClass}</Typography.Title>
        </Form.Item>
        <Form.Item label="Images">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ imageList, onImageUpload, onImageRemove }) => (
              // write your building UI
              <div>
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '15px',
                  }}
                >
                  <Button
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={onImageUpload}
                  >
                    Select Image
                  </Button>
                </div>
                <Row gutter={[32, 6]}>
                  {imageList.map((image, index) => (
                    <Col
                      key={index}
                      span={4}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '0.5px solid grey',
                        borderRadius: '10px',
                        padding: '10px',
                        margin: '20px',
                      }}
                    >
                      <Image
                        src={image?.data_url || image?.url}
                        alt=""
                        width="100"
                      />
                      <Space style={{ marginBottom: 10, marginTop: 10 }}>
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            if (image?.url) {
                              setRemove((previousState) => [
                                ...previousState,
                                { url: image?.url, id: image?.id },
                              ])
                            }
                            onImageRemove(index)
                          }}
                        >
                          Remove
                        </Button>
                      </Space>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </ImageUploading>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TestimonialForm
