/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useAppContext } from '../context'
import {
  collection,
  getDocs,
  getDoc,
  doc,
  orderBy,
  query,
  writeBatch,
  deleteDoc,
  addDoc,
  setDoc,
  where,
} from 'firebase/firestore'
import { db } from '../config'
import { SUCCESS } from '../config'
import { useImage } from '../hooks'

export const useLesson = () => {
  const [loading, setLoading] = useState(false)
  const { user, setError, setMessage } = useAppContext()
  const { multipleUpload, deleteFile } = useImage()

  useEffect(() => {
    if (user?.uid) {
    }
  }, [])

  const getCourses = async (uid, searchKey) => {
    try {
      setLoading(true)

      const courseDocsSnapshot = await getDocs(
        query(collection(db, `course`), orderBy('order', 'asc'))
      )

      const courseDocs = courseDocsSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      const queryDocs = searchKey ? searchKey : courseDocs

      const courseDocsWithUsers =
        (await Promise.all(
          queryDocs.map(async (course) => {
            const courseRef = doc(db, `course`, course.id)
            const profileRef = collection(courseRef, `profile`)
            const profileSnapshot = await getDocs(query(profileRef))

            const subscribedUser = profileSnapshot.docs.map(
              (profile) => profile.id
            )

            return { ...course, users: subscribedUser }
          })
        )) || []

      setLoading(false)

      return courseDocsWithUsers
    } catch (error) {
      setError(error)
    }
  }

  const getCourse = async (id) => {
    try {
      setLoading(true)
      const courseRef = doc(db, `course`, id)
      const lessonRef = collection(courseRef, `lessons`)
      const documentRef = collection(courseRef, `documents`)
      const albumRef = collection(courseRef, `albums`)

      const courseSnapshot = await getDoc(courseRef)
      const course = { id: courseSnapshot.id, ...courseSnapshot.data() }

      const lessonSnapshot = await getDocs(
        query(lessonRef, orderBy('order', 'asc'))
      )

      const lessons = lessonSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      const lessonData =
        (await Promise.all(
          lessons.map(async (lesson) => {
            const contentRef = collection(doc(lessonRef, lesson?.id), 'content')

            const contentSnapshot = await getDocs(
              query(contentRef, orderBy('order', 'asc'))
            )

            const contents =
              contentSnapshot.docs.map((doc) => ({
                id: doc?.id,
                ...doc?.data(),
              })) || []

            return { ...lesson, contents }
          })
        )) || []

      const documentSnapshot = await getDocs(
        query(documentRef, orderBy('order', 'asc'))
      )

      const documents = documentSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      const albumSnapshot = await getDocs(
        query(albumRef, orderBy('order', 'asc'))
      )

      const albums = albumSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data(),
      }))

      setLoading(false)

      return {
        ...course,
        lessons: lessonData,
        documents: documents,
        albums: albums,
      }
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const addCourse = async (data) => {
    try {
      setLoading(true)

      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, 'course'), data)
      setLoading(false)

      setMessage({ type: SUCCESS, message: 'Add Course successful' })

      return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateCourse = async (id, data) => {
    try {
      setLoading(true)

      await setDoc(doc(db, 'course', id), data, {
        merge: true,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Course successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteCourse = async (courseId) => {
    try {
      setLoading(true)
      const courseRef = doc(db, `course`, courseId)

      await deleteDoc(courseRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Course successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const addLesson = async (courseId, data) => {
    try {
      setLoading(true)

      const docRef = await addDoc(
        collection(db, `course/${courseId}/lessons`),
        data
      )
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Add Lesson successful' })

      return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateLesson = async (courseId, id, data) => {
    try {
      setLoading(true)

      await setDoc(doc(db, `course/${courseId}/lessons`, id), data, {
        merge: true,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Lesson successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteLesson = async (courseId, id) => {
    try {
      setLoading(true)
      const courseRef = doc(db, `course/${courseId}/lessons`, id)

      await deleteDoc(courseRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Lesson successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const addDocument = async (courseId, data) => {
    try {
      setLoading(true)

      const docRef = await addDoc(
        collection(db, `course/${courseId}/documents`),
        data
      )
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Add Document successful' })

      return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateDocument = async (courseId, docId, data) => {
    try {
      setLoading(true)

      await setDoc(doc(db, `course/${courseId}/documents`, docId), data, {
        merge: true,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Document successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteDocument = async (courseId, id) => {
    try {
      setLoading(true)
      const courseRef = doc(db, `course/${courseId}/documents`, id)

      await deleteDoc(courseRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Document successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const addAlbum = async (courseId, data, images, folder) => {
    try {
      setLoading(true)

      const results = await multipleUpload(images, folder)

      const docRef = await addDoc(collection(db, `course/${courseId}/albums`), {
        ...data,
        images: results,
      })
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Add Album successful' })

      return docRef
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const updateAlbum = async (
    courseId,
    docId,
    data,
    images,
    folder,
    removeImages
  ) => {
    try {
      setLoading(true)
      const currentImage = images.filter((image) => !image?.file)
      const newImages = images.filter((image) => image?.file)

      let saveImages = []

      if (newImages.length > 0) {
        const results = await multipleUpload(newImages, folder)

        saveImages = [...currentImage, ...results]
      } else if (removeImages.length > 0) {
        const filteredImages = currentImage.filter(
          (file) => file && !removeImages.includes(file.url)
        )

        removeImages.forEach((image) => {
          deleteFile(image)
        })

        saveImages = filteredImages
      } else {
        saveImages = currentImage
      }

      await setDoc(
        doc(db, `course/${courseId}/albums`, docId),
        { ...data, images: saveImages },
        {
          merge: true,
        }
      )

      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Update Album successful' })

      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const deleteAlbum = async (courseId, id) => {
    try {
      setLoading(true)
      const courseRef = doc(db, `course/${courseId}/albums`, id)
      await deleteDoc(courseRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Album successful' })
      return 'success'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const subscribeCourse = async (uid, courseId) => {
    setLoading(true)

    const profileRef = doc(db, `profile/${uid}/course/${courseId}`)
    const courseRef = doc(db, `course/${courseId}/profile/${uid}`)

    const batch = writeBatch(db)

    batch.set(profileRef, {})
    batch.set(courseRef, {})

    await batch.commit()
    return 'success'
  }

  const unsubscribeCourse = async (uid, courseId) => {
    setLoading(true)

    const profileRef = doc(db, `profile/${uid}/course/${courseId}`)
    const courseRef = doc(db, `course/${courseId}/profile/${uid}`)

    const batch = writeBatch(db)

    batch.delete(profileRef)
    batch.delete(courseRef)

    await batch.commit()
    return 'success'
  }

  const seachCourses = async (searchKey) => {
    try {
      const courseSearchKeyDocsSnapshot = await getDocs(
        query(
          collection(db, `course`),
          where('header', '>=', searchKey),
          where('header', '<=', searchKey + '\uf8ff')
        )
      )

      const courseSearchKeyDocs = courseSearchKeyDocsSnapshot.docs.map(
        (doc) => ({
          id: doc?.id,
          ...doc?.data(),
        })
      )

      const courses = await getCourses(null, courseSearchKeyDocs)

      return courses
    } catch (e) {
      console.log(e)
    }
  }

  return {
    getCourses,
    getCourse,
    loading,
    deleteCourse,
    addDocument,
    updateDocument,
    deleteDocument,
    addCourse,
    updateCourse,
    addLesson,
    updateLesson,
    deleteLesson,
    subscribeCourse,
    unsubscribeCourse,
    seachCourses,
    addAlbum,
    updateAlbum,
    deleteAlbum,
  }
}
