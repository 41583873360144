import React from 'react'
import { Form, Input, Button } from 'antd'

import Logo from '../../logo.png'
import './styles.css'

function Login({ login, loading }) {
  function onFinish(values) {
    login(values.email, values.password)
  }
  return (
    <div className="login-container">
      <div className="login">
        <div className="branding">
          <img alt="Cake me home" src={Logo} className="logo" />
          <p className="text">Cake Me Home Control Panel</p>
        </div>
        <div>
          <Form name="login" onFinish={onFinish} autoComplete="off">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="login-btn"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
