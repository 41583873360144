const styles = {
  numberCard: {
    padding: 32,
    marginBottom: 24,
  },
  iconWarp: {
    fontSize: 54,
    float: 'left',
  },

  content: {
    width: '100%',
    paddingLeft: 78,
  },
  title: {
    lineHeight: '16px',
    fontSize: 16,
    marginBottom: 8,
    height: 16,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  number: {
    lineHeight: '32px',
    fontSize: 24,
    height: 32,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}

export default styles
